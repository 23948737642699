<template>
    <v-form>
        <div>
            <v-file-input
                v-model="$v.form.file.$model"
                :error-messages="getErrors('form.file')"
                show-size
                :accept="`.xlsx`"
                label="Файл"
                class="mb-4"
            ></v-file-input>
        </div> 
        
        <v-btn
            outlined
            height="40px"
            :loading="waiting"
            class="button-stylized-blue"
            @click.prevent.stop="upload"
        >
            Загрузить
        </v-btn>
        <error-alert v-if="!_.isNil(serverErrors)" class="mt-5">
            <span v-if="Array.isArray(serverErrors.summary)" v-html="serverErrors.summary.join('')" />
            <span v-else-if="Array.isArray(summaryError)" v-html="summaryError[0]" />
            <span v-else-if="typeof serverErrors === 'string'">{{ serverErrors }}</span>
        </error-alert>
    </v-form>
</template>
<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import ErrorAlert from '../template/ErrorAlert.vue'

export default {
    name: 'UserUploadForm',
    mixins: [errorMixin, saveMixin, validationMixin],
    components: { ErrorAlert },
    data() {
        return {
            form: {
                file: null
            },
            waiting: false
        };
    },
    validations() {
        return {
            form: {
                file: {
                  required: function(value) {
                    return !_.isNil(value);
                  }
                }
            }
        }
    },
    methods: {
        async upload() {
            if (this.validate()) {
                this.waiting = true;
                this.serverErrors = null;

                let form = new FormData();
                _.forOwn(this.form, (v,k) => {
                    form.append(k, v);
                })

                const { success, error } = await this.$store.dispatch('user/upload', form);

                if(!success)
                    this.serverErrors = error;
                else
                    this.$emit('back', 1);

                this.waiting = false;
                return ;
            }
        }
    }
}
</script>